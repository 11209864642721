import React, { Component } from 'react';
import NavBar from './navbar';
import { Link, withRouter, NavLink } from 'react-router-dom';
import NavBurger from './navBurger';
import NavMobile from './navMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/fontawesome-free-solid';
import TopBarDiv from './topBarDiv';

class Header extends Component {
  state = {
    imageUrl: '/assets/logo.png',
    navMobActive: false,
  };

  conditionalToggle = () => {
    if (!this.state.navMobActive) return;
    this.toggleNavMobActive();
  };

  toggleNavMobActive = () => {
    const active = this.state.navMobActive ? false : true;
    this.setState({ navMobActive: active });
  };

  render() {
    const path = this.props.location.pathname;
    const narrow =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? '-narrow'
        : ' ';
    const small =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? '-small'
        : ' ';
    const relative =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? 'relative'
        : ' ';
    const showHide =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin' ||
      path === '/members'
        ? 'hide'
        : ' ';

    const { user } = this.props;

    // So we don't need to concat px inside styled-components. Because if auto, autopx is false property
    // Maybe we could omit 'auto' all together??
    const topBarHeight =
      this.props.topBarHeight === 'auto'
        ? 'auto'
        : this.props.topBarHeight + 'px';

    return (
      <header className={relative}>
        <TopBarDiv
          ref={this.props.childRef}
          showHide={showHide}
          handleTopBarClose={this.props.handleTopBarClose}
          topBarHeight={topBarHeight}
          topBarClosed={this.props.topBarClosed}
          topBarTransition={this.props.topBarTransition}
          topBarSettings={this.props.topBarSettings}
        />
        {user && (
          <div className="logged-in">
            {/* { (user.isAdmin || user.role === 'membersAdmin') && <NavLink to="/members" style={{color:"black", marginRight: 60 }}>Members</NavLink>} */}
            {(user.isAdmin ||
              user.role?.some((elem) => ['membersAdmin'].includes(elem))) && (
              <NavLink
                to="/members"
                style={{ color: 'black', marginRight: 60 }}
              >
                Members
              </NavLink>
            )}
            <FontAwesomeIcon icon={faUser} /> <span>{user.name}</span>
            <NavLink to="/logout">Logout</NavLink>
            {/* { (user.isAdmin || user.role === 'register') && <NavLink to="/register" style={{color:"black", marginRight: 60 }}>Register</NavLink>} */}
            {(user.isAdmin ||
              user.role?.some((elem) => ['register'].includes(elem))) && (
              <NavLink
                to="/register"
                style={{ color: 'black', marginRight: 60 }}
              >
                Register
              </NavLink>
            )}
          </div>
        )}
        <div className={`margin-container${narrow}`}>
          <Link to="/" onClick={this.conditionalToggle}>
            <img
              className={`logo${small}`}
              src={this.state.imageUrl}
              alt="logo"
            />
          </Link>
          <NavBar />
          <NavBurger
            toggleNavMobActive={this.toggleNavMobActive}
            navMobActive={this.state.navMobActive}
          />
          <NavMobile
            toggleNavMobActive={this.toggleNavMobActive}
            navMobActive={this.state.navMobActive}
            topBarClosed={this.props.topBarClosed}
            mainTopMargin={this.props.mainTopMargin}
            mainTransition={this.props.mainTransition}
          />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
